import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import Hero from "../../components/hero/hero";
import Img from "gatsby-image";
import Logo from "../../images/svg/aplinet_logo_text.inline.svg";
import "./index.scss";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import HeaderBig from "../../components/header-big/header-big";
import { InView } from "react-intersection-observer";
import Button from "../../components/button/button";
import BackgroundImage from "gatsby-background-image";
import "swiper/swiper.scss";
import Swiper from "react-id-swiper";
import { getMouseWheelEvent } from "../../utils/utils";
import Footer from "../../components/footer/footer";
import CuremMp4 from "../../assets/curem.mp4";
import CuremWebm from "../../assets/curem.webm";
import WallersMp4 from "../../assets/wallers.mp4";
import WallersWebm from "../../assets/wallers.webm";
// import GooEffect from "../../assets/goo-effect.inline.svg";
import ShowMore from "../../images/svg/show-more.inline.svg";
import OfferShortcut from "../../components/offer-shortcut/offer-shortcut";
import Section from "../../components/air-section/air-section";
import Customers from "../../components/customers/customers";
import { GlobalState, LayoutState } from "../../components/layout/global-state";
import { Video } from "../../components/video/video";
import { Modal } from "./modal";
import PortfolioItemPage from "../portfolio/portfolio-item";
import ShowLess from "../../images/svg/show-less.inline.svg";
import GoogleRatingBadge from "../../images/homepage/google-play-5-stars.inline.svg";
import UsersBadge from "../../images/homepage/users.inline.svg";
import TimeBadge from "../../images/homepage/time.inline.svg";
import KomputerSwiatLogo from "../../images/homepage/komputer-swiat-logo.svg";
import ScrollButton from "../../components/scroll-button/scroll-button";
import OfferShortcutEn from "../../components/offer-shortcut/offer-shortcut.en";

class IndexEnPage extends React.Component {
  language = "en";
  state = {
    stickyElement: null,
    loadModals: {
      curem: false,
      "zielone-pogotowie-app": false,
      wallers: false,
      carnet: false,
      "simplic-bp": false,
    },
  };
  swiperInstance = null;
  videoMap: Map<HTMLVideoElement, (event) => any> = new Map();
  slideContentReadyTimout = null;
  swiperParams = {
    direction: "vertical",
    parallax: { enabled: true },
    speed: 500,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    on: {
      slideChange: () => {
        if (this.slideContentReadyTimout) {
          clearTimeout(this.slideContentReadyTimout);
        }
        this.swiperInstance.slides[
          this.swiperInstance.previousIndex
        ].classList.remove("swiper-slide-ready");
        this.swiperInstance.slides[
          this.swiperInstance.previousIndex
        ].classList.remove("swiper-slide-content-ready");
        switch (this.swiperInstance.activeIndex) {
          case 0:
            GlobalState.set({ headerColor: "#000" });

            break;
          case 1:
            GlobalState.set({ headerColor: "#e8c0ac" });
            break;
          case 2:
            GlobalState.set({ headerColor: "#fff" });
            break;
          case 3:
            GlobalState.set({ headerColor: "#000" });
            break;
          case 4:
            GlobalState.set({ headerColor: "#000" });
            break;
          case 5:
            GlobalState.set({ headerColor: "#fff" });
            break;

          default:
            GlobalState.set({ headerColor: null });
            break;
        }
      },
      slideChangeTransitionEnd: () => {
        if (!this.swiperInstance.animating) {
          this.swiperInstance.slides[this.swiperInstance.activeIndex].focus();
          this.swiperInstance.slides[
            this.swiperInstance.activeIndex
          ].classList.add("swiper-slide-ready");
          const hasDescription =
            this.swiperInstance.slides[
              this.swiperInstance.activeIndex
            ].querySelector(".description") !== null;

          this.slideContentReadyTimout = setTimeout(
            () => {
              this.swiperInstance.slides[
                this.swiperInstance.activeIndex
              ].classList.add("swiper-slide-content-ready");
            },
            hasDescription ? 1800 : 1000
          );
        }
      },
    },
  };
  experienceItems = [
    {
      name: "IT Project documentation",
      to: "/",
      description: "When preparing project specification, we select best suited technologies, estimate development time of individual modules and cost estimate of the entire project",
    },
    {
      name: "AGILE, SCRUM, WATERFALL",
      to: "/",
      description: "We know how valuable time can be. Planning, analysis, design, implementation, testing - we know how to run a project in stages and ship on time.",
    },
    // Customer satisfaction is our top priority.
    {
      name: "UI/UX Prototyping",
      to: "/",
      description: "We prototype interfaces by analyzing the needs of the target audience. We can prepare a simple mock-up or an advanced interactive interface.",
    },
    {
      name: "Testing (E2E & Unit)",
      to: "/",
      description: "We create automatic tests of native, web and hybrid applications on iOS and Android platforms.",
    },
    {
      name: "Code Review",
      to: "/",
      description: "Better team equals better results. We uphold best practices and verify code quality with code reviews.",
    },
  ];

  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
    this.setStickyElement = this.setStickyElement.bind(this);
    this.getSwiper = this.getSwiper.bind(this);
    this.setVideoRef = this.setVideoRef.bind(this);
    console.log(params.pageContext);
  }

  setVideoRef(element: HTMLVideoElement) {
    if (element) {
      const duration = element.getAttribute("data-duration")
        ? parseInt(element.getAttribute("data-duration"), 10)
        : null;
      if (duration) {
        const callback = (event: any) => {
          if (event.srcElement.currentTime > duration) {
            event.srcElement.currentTime = 0;
          }
        };
        element.addEventListener("timeupdate", callback);
        this.videoMap.set(element, callback);
      }
    }
  }
  // componentDidMount() {
  //   if (this.swiperInstance) {
  //     this.swiperInstance.update();
  //     console.log("update swiper");
  //   }
  //   console.log("didMount");
  // }
  componentWillUnmount() {
    if (document) {
      document.removeEventListener("keydown", this.onKeyDown.bind(this), true);
    }
    GlobalState.set({ headerColor: null });

    this.videoMap.forEach((callback, videoElement) => {
      videoElement.removeEventListener("timeupdate", callback);
    });
  }

  setStickyElement(element: Element) {
    // this.setState({ stickyElement: element });
  }
  slideNext() {
    if (this.swiperInstance) {
      if (
        !this.swiperInstance.animating ||
        this.swiperInstance.previousIndex > this.swiperInstance.activeIndex
      ) {
        this.swiperInstance.slideNext();
      }
    }
  }
  slidePrev() {
    if (this.swiperInstance) {
      if (
        !this.swiperInstance.animating ||
        this.swiperInstance.previousIndex < this.swiperInstance.activeIndex
      ) {
        this.swiperInstance.slidePrev();
      }
    }
  }
  onKeyDown(event: any) {
    if (this.swiperInstance) {
      const keyCode = event.keyCode || event.charCode;
      let slide;
      let scrollDiff;
      switch (keyCode) {
        case 37:
          // left
          slide = this.swiperInstance.slides[this.swiperInstance.activeIndex];
          const projectName = slide.getAttribute("data-project");
          if (projectName) {
            this.openModal(projectName);
          }
          break;
        case 39:
          //right
          this.closeModal();
          break;
        case 40:
          // next
          if (Modal.isOpen()) {
            event.stopPropagation();
            return;
          }
          slide = this.swiperInstance.slides[this.swiperInstance.activeIndex];

          scrollDiff = slide.scrollHeight - slide.offsetHeight;
          if (scrollDiff > 0) {
            if (slide.scrollTop < scrollDiff) {
              event.stopPropagation();
            }
          }
          break;
        case 38:
          // prev
          if (Modal.isOpen()) {
            event.stopPropagation();
            return;
          }
          slide = this.swiperInstance.slides[this.swiperInstance.activeIndex];
          scrollDiff = slide.scrollHeight - slide.offsetHeight;

          if (scrollDiff > 0) {
            if (slide.scrollTop > 0) {
              event.stopPropagation();
            }
          }

          break;

        default:
          break;
      }
    }
  }
  getSwiper(swiper: any) {
    if (swiper) {
      this.swiperInstance = swiper;
      const component = this;

      let startScroll: number;
      let touchStart: number;
      let touchCurrent: any;

      swiper.slides.on(
        "touchstart",
        function (e) {
          startScroll = this.scrollTop;
          touchStart = e.targetTouches[0].pageY;
        },
        true
      );
      if (document) {
        document.addEventListener("keydown", this.onKeyDown.bind(this), true);
      }
      swiper.slides.on(
        getMouseWheelEvent(),
        function (event) {
          const scrollDiff = this.scrollHeight - this.offsetHeight;
          const PIXEL_STEP = 10;
          let sY = 0;
          let pY = 0;
          if ("detail" in event) {
            sY = event.detail;
          }
          if ("wheelDelta" in event) {
            sY = -event.wheelDelta / 120;
          }
          if ("wheelDeltaY" in event) {
            sY = -event.wheelDeltaY / 120;
          }
          pY = sY * PIXEL_STEP;

          if ("deltaY" in event) {
            pY = event.deltaY;
          }

          if (scrollDiff > 0) {
            if (pY < 0) {
              if (this.scrollTop > 0) {
                event.stopPropagation();
              } else {
                component.slidePrev();
              }
            } else if (pY > 0) {
              if (this.scrollTop < scrollDiff) {
                event.stopPropagation();
              } else {
                component.slideNext();
              }
            }
          } else {
            if (pY < 0) {
              component.slidePrev();
            } else if (pY > 0) {
              component.slideNext();
            }
          }
          event.stopPropagation();
        },
        true
      );
      swiper.slides.on(
        "touchmove",
        function (e) {
          touchCurrent = e.targetTouches[0].pageY;
          var touchesDiff = touchCurrent - touchStart;
          var slide = this;
          if (
            startScroll > slide.scrollHeight - slide.offsetHeight &&
            touchesDiff > 0
          ) {
            e.stopPropagation();
            return;
          }
          // document.querySelector(".site-header").innerHTML =
          //   "scroll: " + startScroll + " tdiff: " + touchesDiff;
          var onlyScrolling =
            slide.scrollHeight > slide.offsetHeight &&
            ((touchesDiff < 0 && startScroll === 0) ||
              (touchesDiff > 0 &&
                startScroll === slide.scrollHeight - slide.offsetHeight) ||
              (startScroll > 0 &&
                startScroll < slide.scrollHeight - slide.offsetHeight));
          if (onlyScrolling) {
            e.stopPropagation();
          }
        },
        true
      );
      let timeout = 0;
      if (document) {
        const body = document.body;
        if (body && !body.classList.contains("has-hero-image")) {
          timeout = 100;
        }
      }
      setTimeout(() => {
        swiper.update();
      }, timeout);
    }
  }

  openModal(project: string) {
    if (!this.state.loadModals[project]) {
      const loadModals = { ...this.state.loadModals };
      loadModals[project] = true;
      this.setState({
        loadModals: loadModals,
      });
    }
    setTimeout(() => {
      Modal.open(project);
    });
  }
  closeModal() {
    Modal.close();
  }

  render() {
    console.log("render");
    return (
      <>
        <SEO
          title="🥇 Aplinet - Interactive Agency and Software House"
          titleTemplate="%s"
          hasHeroImage={true}
          lang={this.language}
        />
        <StaticQuery
          render={data => (
            <>
              <div id="modal">
                <div
                  className="less-button"
                  onClick={() => {
                    this.closeModal();
                  }}
                >
                  <ShowLess />
                </div>

                {this.state.loadModals["curem"] && (
                  <div className="project" tabIndex="0" id="curem">
                    <PortfolioItemPage
                      modal={true}
                      pageContext={
                        this.params.pageContext.portfolioContexts["curem"]
                      }
                    />
                  </div>
                )}
                {this.state.loadModals["zielone-pogotowie-app"] && (
                  <div
                    className="project"
                    tabIndex="0"
                    id="zielone-pogotowie-app"
                  >
                    <PortfolioItemPage
                      modal={true}
                      pageContext={
                        this.params.pageContext.portfolioContexts[
                        "zielone-pogotowie-app"
                        ]
                      }
                    />
                  </div>
                )}
                {this.state.loadModals["wallers"] && (
                  <div className="project" tabIndex="0" id="wallers">
                    <PortfolioItemPage
                      modal={true}
                      pageContext={
                        this.params.pageContext.portfolioContexts["wallers"]
                      }
                    />
                  </div>
                )}
                {this.state.loadModals["simplic-bp"] && (
                  <div className="project" tabIndex="0" id="simplic-bp">
                    <PortfolioItemPage
                      modal={true}
                      pageContext={
                        this.params.pageContext.portfolioContexts["simplic-bp"]
                      }
                    />
                  </div>
                )}
                {this.state.loadModals["carnet"] && (
                  <div className="project" tabIndex="0" id="carnet">
                    <PortfolioItemPage
                      modal={true}
                      pageContext={
                        this.params.pageContext.portfolioContexts["carnet"]
                      }
                    />
                  </div>
                )}
              </div>
              <div className="modal-overlay" onClick={this.closeModal}></div>
              <Swiper {...this.swiperParams} getSwiper={this.getSwiper}>
                <Hero style={{ height: "100%" }}>
                  <div className="container-xs header-padding">
                    <Logo className="aplinet-logo" />
                    {/* <h1 >Aplinet</h1> */}
                    <div className="header-subline">
                      <h2 className="subtitle">
                        Where business challenges meet technical solutions
                      </h2>
                    </div>
                    <h4>
                      Since 2013, we have been designing and implementing scalable IT systems and digital products.
                      We provide services, teams and solutions to solve our clients IT needs.
                    </h4>
                  </div>
                  <ScrollButton style={{
                    position: "absolute",
                    left: "50%",
                    bottom: "5%",
                    transform: "translateX(-50%)"

                  }} onClick={() => { this.slideNext(); }} />
                </Hero>
                <div className="slide-project" data-project="curem">
                  <div className="more-button">
                    <div
                      className="arrow"
                      onClick={() => {
                        this.openModal("curem");
                      }}
                    >
                      <ShowMore />
                    </div>
                  </div>
                  <div className="content">
                    <div className="caption">
                      <h3 className="header">Curem</h3>
                      <div className="physical-subline"></div>
                      <p className="sub-header">website, design</p>
                    </div>
                  </div>
                  <div
                    className="video-wrapper"
                    style={{ backgroundColor: "black" }}
                  >
                    <Video
                      autoPlay
                      muted
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        width: "100%",
                        height: "100%",
                        transform: "translate3d(-50%,-50%,0)",
                        objectFit: "cover",
                        pointerEvents: "none",
                      }}
                      data-duration="40"
                      videoRef={this.setVideoRef}
                      src={{ mp4: CuremMp4, webm: CuremWebm }}
                    ></Video>
                  </div>
                </div>
                <div
                  className="slide-project"
                  data-project="zielone-pogotowie-app"
                  style={{ background: "black" }}
                >
                  <div className="more-button">
                    <div
                      className="arrow"
                      onClick={() => {
                        this.openModal("zielone-pogotowie-app");
                      }}
                    >
                      <ShowMore />
                    </div>
                  </div>
                  <div className="content">
                    <div className="description">
                      <div className="badge-wrapper">
                        <div className="badge">
                          <GoogleRatingBadge />
                          <span>
                            Great ratings on Google Play
                          </span>
                        </div>
                        <div className="badge">
                          <img
                            src={KomputerSwiatLogo}
                            alt="Komputer Świat logo"
                            style={{ width: "200px" }}
                          />
                          <span className="quote">
                            Zielone Pogotowie will surely be a favorite app of every garden lover
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caption">
                      <h3 className="header">Zielone pogotowie</h3>
                      <div className="physical-subline"></div>
                      <p className="sub-header">
                        mobile app, website
                      </p>
                    </div>
                  </div>
                  <BackgroundImage
                    className="slide-background"
                    tag="div"
                    fluid={data.zielone.nodes[0].childImageSharp.fluid}
                  ></BackgroundImage>
                </div>
                <div
                  className="slide-project"
                  data-project="wallers"
                  style={{ background: "black" }}
                >
                  <div className="more-button">
                    <div
                      className="arrow"
                      onClick={() => {
                        this.openModal("wallers");
                      }}
                    >
                      <ShowMore />
                    </div>
                  </div>
                  <div className="content">
                    <div className="caption">
                      <h3 className="header">Wallers</h3>
                      <div className="physical-subline"></div>
                      <p className="sub-header">e-commerce</p>
                    </div>
                  </div>
                  <div
                    className="video-wrapper"
                    style={{ backgroundColor: "black" }}
                  >
                    <Video
                      videoRef={this.setVideoRef}
                      data-duration="20"
                      autoPlay
                      muted
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        width: "100%",
                        height: "100%",
                        transform: "translate3d(-50%,-50%,0)",
                        objectFit: "cover",
                        pointerEvents: "none",
                      }}
                      src={{ mp4: WallersMp4, webm: WallersWebm }}
                    ></Video>
                  </div>
                  {/* <BackgroundImage
                  className="slide-background"
                  
                  tag="div"
                  fluid={data.wallers.nodes[0].childImageSharp.fluid}
                ></BackgroundImage> */}
                </div>
                <div
                  className="slide-project"
                  data-project="simplic-bp"
                  style={{ background: "black" }}
                >
                  <div className="more-button">
                    <div
                      className="arrow"
                      onClick={() => {
                        this.openModal("simplic-bp");
                      }}
                    >
                      <ShowMore />
                    </div>
                  </div>
                  <div className="content">
                    <div className="caption">
                      <h3 className="header">BP Advertisement Manager</h3>
                      <div className="physical-subline"></div>
                      <p className="sub-header">
                        web app, mobile app
                      </p>
                    </div>
                  </div>
                  <BackgroundImage
                    className="slide-background"
                    tag="div"
                    fluid={data.bp.nodes[0].childImageSharp.fluid}
                  ></BackgroundImage>
                </div>
                <div
                  className="slide-project"
                  data-project="carnet"
                  style={{ background: "black" }}
                >
                  <div className="more-button">
                    <div
                      className="arrow"
                      onClick={() => {
                        this.openModal("carnet");
                      }}
                    >
                      <ShowMore />
                    </div>
                  </div>
                  <div className="content">
                    <div className="description">
                      <div className="badge-wrapper">
                        <div className="badge">
                          <UsersBadge />
                          <span>
                            Over 100 active users
                          </span>
                        </div>
                        <div className="badge">
                          <TimeBadge />
                          <span>
                            Long-term project
                            <br />
                            Almost 2 years of development and support
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caption">
                      <h3 className="header">Carnet</h3>
                      <div className="physical-subline"></div>
                      <p className="sub-header">web app</p>
                    </div>
                  </div>
                  <BackgroundImage
                    className="slide-background"
                    tag="div"
                    fluid={data.carnet.nodes[0].childImageSharp.fluid}
                  ></BackgroundImage>
                </div>
                <div tabIndex="0">
                  <Section>
                    <div className="container">
                      <HeaderBig
                        tag="h2"
                        span="Development, Design, Dedication"
                        headerText="Our offer"
                      />
                    </div>
                    <div>
                      <OfferShortcutEn context={this.params.pageContext} />
                    </div>
                    <div data-sticky className="container">
                      <div className="align-center air-mt:2">
                        <Button
                          color="primary"
                          type="default"
                          to="/offer"
                          language={this.language}
                        >
                          Show offer
                        </Button>
                      </div>
                    </div>
                  </Section>
                  <Section>
                    <StickyContainer
                      setElement={this.setStickyElement}
                      className="r :a-t :c-6 :c-12:xs :c-12:xxs :w"
                    >
                      <div data-sticky className="container">
                        <HeaderBig
                          tag="h2"
                          span="Experience"
                          headerText="We thrive in digital"
                        />
                        <p>
                          We support our clients from the early stage of the analysis
                          and planning through implementation, evaluation and audit of the finished product
                        </p>
                        <Button to="/about/" language={this.language}>
                          Get to know us
                        </Button>
                        <Button
                          color="primary"
                          type="default"
                          to="/portfolio"
                          language={this.language}
                        >
                          Our works
                        </Button>
                      </div>
                      <ul className="experience count container">
                        {this.experienceItems.map((item, index) => {
                          return (
                            <InView key={index} rootMargin="-49% 0px -49% 0px">
                              {({ inView, ref, entry }) => (
                                <li
                                  ref={ref}
                                  className={inView ? "active" : ""}
                                >
                                  <div>
                                    <h3
                                      dangerouslySetInnerHTML={{
                                        __html: item.name,
                                      }}
                                    ></h3>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></p>
                                  </div>
                                </li>
                              )}
                            </InView>
                          );
                        })}
                      </ul>
                    </StickyContainer>
                  </Section>
                  <Section className="container-xs">
                    <HeaderBig
                      tag="h2"
                      span="Over 400 clients"
                      headerText="7 years implementing commercial solutions"
                    />
                    <h3 style={{ color: "#1e4d47" }}>
                      People matter. Through cooperation we acquire best possible results.
                    </h3>
                    <p>
                      We've been trusted by <strong>over 400 clients from around the world</strong>
                      <br />
                      We had the pleasure to cooperate with people from countries like Poland, Australia, South Korea, Sweden, Norway, France and the United States
                    </p>
                    <p>
                      Customer satisfaction is our top priority. We thank our customer for the rating of{' '}
                      <strong>
                        46 points on NPS.
                      </strong>
                    </p>
                    <h4>
                      We appreciate the positive feedback, and wont stop striving to get better.
                    </h4>
                  </Section>
                  <Customers />
                  <CallFooter lang={this.language} />
                  <Footer context={this.params.pageContext} />
                </div>
              </Swiper>
            </>
          )}
          query={graphql`
            query ImageQueryEn {
              zielone: allFile(
                filter: {
                  sourceInstanceName: { eq: "portfolio" }
                  relativePath: { eq: "zielone-pogotowie-app/huge.png" }
                }
              ) {
                nodes {
                  id
                  absolutePath
                  name
                  ext
                  extension
                  relativePath
                  relativeDirectory
                  dir
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              wallers: allFile(
                filter: {
                  sourceInstanceName: { eq: "portfolio" }
                  relativePath: { eq: "wallers/hero.png" }
                }
              ) {
                nodes {
                  id
                  absolutePath
                  name
                  ext
                  extension
                  relativePath
                  relativeDirectory
                  dir
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              bp: allFile(
                filter: {
                  sourceInstanceName: { eq: "portfolio" }
                  relativePath: { eq: "simplic-bp/hero.png" }
                }
              ) {
                nodes {
                  id
                  absolutePath
                  name
                  ext
                  extension
                  relativePath
                  relativeDirectory
                  dir
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              carnet: allFile(
                filter: {
                  sourceInstanceName: { eq: "portfolio" }
                  relativePath: { eq: "carnet/hero.jpg" }
                }
              ) {
                nodes {
                  id
                  absolutePath
                  name
                  ext
                  extension
                  relativePath
                  relativeDirectory
                  dir
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          `}
        />
      </>
    );
  }
}

export default IndexEnPage;
