import React from "react";
import ALink from "../../components/a-link/a-link";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";


import { PageContext, PostPageContext } from "../../../types";

import "./offer-shortcut.scss";

interface Props {
  children?: any;
  className?: string;
  context: PageContext | PostPageContext;
}

class OfferShortcutEn extends React.Component {
  language = "en";

  constructor(public params: Props) {
    super(params);
    this.language = params.context.language;
  }

  serviceIcon = [
    {
      name: "Websites",
      to: "/websites",
      img: ServiceIconWebsite,
      alt: "Icon of a browser displaying a website",
    },
    {
      name: "E-commerce",
      to: "/e-commerce",
      img: ServiceIconECommerce,
      alt: "Icon of a shopping cart",
    },
    {
      name: "Web platforms",
      to: "/web-platforms",
      img: ServiceIconDesktop,
      alt: "Icon of a monitor",
    },
    {
      name: "Web apps",
      to: "/web-apps",
      img: ServiceIconSoftware,
      alt: "Icon of a cog with coding tag",
    },
    {
      name: "Mobile apps",
      to: "/mobile-apps",
      img: ServiceIconMobile,
      alt: "Smart phone icon",
    },
    {
      name: "Design",
      to: "/design",
      img: ServiceIconDesign,
      alt: "Icon of a logo during design phase",
    },
    {
      name: "Consulting",
      to: "/consulting",
      img: ServiceIconConsulting,
      alt: "Icon of a crossroad direction sign",
    },
    {
      name: "Support",
      to: "/support",
      img: ServiceIconInfrastructure,
      alt: "Icon of a server rack",
    },
  ];

  render() {
    return (
      <>
        <div className={this.params.className + " offer-shortcut-wrapper"}>
          <ul className="r :c-3 :g :w :c-3:md :c-6:sm :c-6:xs :c-6:xxs :a-se ">
            {this.serviceIcon.map((item, index) => {
              return (
                <li key={index}>
                  <ALink
                    key={index}
                    title={item.name}
                    className="image"
                    to={item.to}
                    language={this.language}
                  >
                    <div>
                      <img src={item.img} alt={item.alt} key={index} />
                      {/* {item.src} */}
                      <h4>{item.name}</h4>
                    </div>
                  </ALink>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}

export default OfferShortcutEn;
